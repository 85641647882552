<template>
    <v-container fluid>
        <v-alert v-model="alert" type="error" dense text>
        加载文章出错
        </v-alert>
        <h1 class="mt-3 mb-3">{{ title }}</h1>
        <div class="d-flex align-center mb-8" v-if="author">
            <v-icon class="mr-1">mdi-account</v-icon>
            <span>{{ author }}</span>
            <v-icon class="ml-4 mr-1">mdi-calendar</v-icon>
            <span>{{ publishTime }}</span>
        </div>
        <div v-html="content"></div>
    </v-container>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

export default {
    name: 'home',
    data() {
        return {
            title: '',
            author: '',
            publishTime: '',
            content: '',
            alert: false
        }
    },
    created() {
        axios.get(`https://qidafang.com/api/article/${this.$route.params.name}`)
            .then(response => {
                const data = response.data
                if(data) {
                    this.title = data.title
                    this.content = data.content
                    this.author = data.author
                    this.publishTime = moment(data.publishTime).format('YYYY-MM-DD')
                }else {
                    this.alert = true
                }
            })
            .catch(error => {
                this.alert = true
            })
    },
}
</script>

<style scoped>
.container {
    max-width: 800px;
}

p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
}

ul {
    margin-bottom: 20px;
}

li {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 10px;
}

code {
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    font-size: 14px;
    background-color: #f5f5f5;
    padding: 2px 4px;
    border-radius: 4px;
}

pre {
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    font-size: 14px;
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 4px;
    overflow-x: auto;
}

img {
    max-width: 100%;
    margin-bottom: 20px;
}
</style>